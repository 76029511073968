import { AirplaneTilt, Boat, IconProps, Package, Train } from "phosphor-react";
import { AnyTransportMode, TransportModeName, TransportModeNameV2 } from "./constants";
import TruckIcon from "../../../../public/icons/svg/Truck.svg";
import SeaContainerIcon from "public/icons/svg/SeaContainerIcon.svg";
import PalletsIcon from "public/icons/svg/Pallets.svg";

export const TransportIcon: React.FC<
  { mode: AnyTransportMode | null; className?: string } & Omit<IconProps, "mode">
> = ({ mode, ...restProps }) => {
  switch (mode) {
    case TransportModeName.Air:
      return <AirplaneTilt {...restProps} />;
    case TransportModeNameV2.Rail:
    case TransportModeName.Land:
      return <Train {...restProps} />;
    case TransportModeName.Sea:
      return <Boat {...restProps} />;
    case TransportModeName.Parcel:
      return <Package {...restProps} />;
    case TransportModeNameV2.Road:
      return <TruckIcon {...restProps} />;
    default:
      return null;
  }
};

export const CargoIcon = ({
  mode,
  ...rest
}: {
  mode: AnyTransportMode | null;
  className?: string;
  weight?: IconProps["weight"];
  size?: number;
}) => {
  switch (mode) {
    case TransportModeNameV2.Sea:
      return <SeaContainerIcon {...rest} />;
    case TransportModeNameV2.Air:
      return <PalletsIcon {...rest} />;
    default:
      return <Package {...rest} />;
  }
};
