import {
  PresentationChart,
  Gear,
  Path,
  IconProps,
  ListNumbers,
  Package,
  ArrowCircleDown,
} from "phosphor-react";
import Tower from "public/icons/svg/Tower.svg";
import ClipboardChecks from "public/icons/svg/ClipboardChecks.svg";
import Dispatched from "public/icons/svg/Dispatched.svg";
import { getOperationRoute, routes } from "holocene-constants/routes";
import Link from "next/link";
import { useRouter } from "next/router";
import { useActiveOperation } from "holocene-hooks/utils.hooks";
import { useUserDetails } from "holocene-providers/common";
import { useEffect, useMemo } from "react";
import { useGetCurrentUserInfo } from "holocene-hooks/users.hooks";
import { FeaturePermissionEnum } from "holocene-services/user.service/types";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";

export type IMenuItem = {
  icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
  path: string;
  text: string;
  disabled?: boolean;
  activationPaths: string[];
};
const MenuItem = ({ menu, activeOperation }: { menu: IMenuItem; activeOperation: ModuleEnum }) => {
  const { pathname } = useRouter();

  const { icon, path, text, activationPaths } = menu;
  const MenuIcon = icon;

  const isActive = activationPaths.some((activationPath) => pathname.includes(activationPath));

  const activeBg = isActive
    ? activeOperation === ModuleEnum.sales
      ? "bg-blue-1"
      : "bg-orange-6"
    : activeOperation === ModuleEnum.sales
    ? "bg-ice-blue-2"
    : "bg-orange-4";

  return (
    <Link
      key={text}
      href={menu.disabled ? window.location.pathname : `${path}`}
      passHref
      aria-disabled={menu.disabled}
      legacyBehavior
    >
      <a
        className={`cursor-pointer w-10 h-10 z-50 flex items-center p-2.5 group hover:w-max hover:${activeBg} rounded-lg mb-2 ${activeBg} ${
          menu.disabled ? "cursor-default" : ""
        }`}
      >
        <MenuIcon
          className={`${activeOperation === ModuleEnum.sales ? "text-navy-4" : "text-orange-5"}`}
          weight="regular"
          size={20}
        />
        <div
          className={`text-xs ${
            activeOperation === ModuleEnum.sales ? "text-navy-4" : "text-orange-5"
          } hidden group-hover:block ml-2 mr-1`}
        >
          {text}
        </div>
      </a>
    </Link>
  );
};

const salesMenuItems = (permissionMap: Record<string, boolean>, isSupportUser: boolean) => {
  return [
    {
      icon: Tower,
      path: getOperationRoute("controlTower", ModuleEnum.sales),
      text: "Control Tower",
      activationPaths: ["sales/control-tower", "purchase/control-tower"],
      hidden: !permissionMap[FeaturePermissionEnum.CONTROL_TOWER] && !isSupportUser,
    },
    {
      icon: PresentationChart,
      path: getOperationRoute("performanceDashboard", ModuleEnum.sales),
      text: "Performance Dashboard",
      activationPaths: ["performance-dashboard"],
      hidden: !permissionMap[FeaturePermissionEnum.PERFORMANCE] && !isSupportUser,
    },
    {
      icon: ArrowCircleDown,
      path: routes.salesIncoming,
      text: "Incoming Orders",
      activationPaths: ["in-coming"],
      hidden: !permissionMap[FeaturePermissionEnum.INCOMING_ORDERS] && !isSupportUser,
    },
    {
      icon: ClipboardChecks,
      path: routes.deliveryQueue,
      text: "Open Deliveries",
      activationPaths: ["in-queue"],
      hidden: !permissionMap[FeaturePermissionEnum.DELIVERY] && !isSupportUser,
    },
    {
      icon: Dispatched,
      path: routes.deliveryDispatched,
      text: "Dispatched Deliveries",
      activationPaths: ["dispatched", "delivered"],
      hidden: !permissionMap[FeaturePermissionEnum.DISPATCH] && !isSupportUser,
    },
    {
      icon: Path,
      path: getOperationRoute("tradelanes", ModuleEnum.sales),
      text: "Trade Lanes",
      activationPaths: ["trade-lanes"],
      hidden: !permissionMap[FeaturePermissionEnum.TRADE_LANES] && !isSupportUser,
    },
    {
      icon: Gear,
      path: getOperationRoute("settings", ModuleEnum.sales),
      text: "Settings",
      activationPaths: ["settings"],
      hidden: !permissionMap[FeaturePermissionEnum.SETTINGS] && !isSupportUser,
    },
  ];
};

const purchaseMenuItems = (permissionMap: Record<string, boolean>, isSupportUser: boolean) => {
  return [
    {
      icon: Tower,
      path: getOperationRoute("controlTower", ModuleEnum.purchase),
      text: "Control Tower",
      activationPaths: ["control-tower"],
      hidden: !permissionMap[FeaturePermissionEnum.CONTROL_TOWER] && !isSupportUser,
    },

    {
      icon: ListNumbers,
      path: getOperationRoute("shipmentsOrderList", ModuleEnum.purchase),
      text: "Orders List",
      activationPaths: ["purchase-orders"],
      hidden: !permissionMap[FeaturePermissionEnum.PURCHASE_ORDERS] && !isSupportUser,
    },
    {
      icon: Package,
      path: getOperationRoute("ShipmentDeliveryOrdersList", ModuleEnum.purchase),
      text: "Inbound Delivery List",
      activationPaths: ["inbound-deliveries"],
      hidden: !permissionMap[FeaturePermissionEnum.DELIVERY] && !isSupportUser,
    },
    {
      icon: Path,
      path: getOperationRoute("tradelanes", ModuleEnum.purchase),
      text: "Trade Lanes",
      activationPaths: ["trade-lanes"],
      hidden: !permissionMap[FeaturePermissionEnum.TRADE_LANES] && !isSupportUser,
    },
    {
      icon: Gear,
      path: getOperationRoute("settings", ModuleEnum.purchase),
      text: "Settings",
      activationPaths: ["settings"],
      hidden: !permissionMap[FeaturePermissionEnum.SETTINGS] && !isSupportUser,
    },
  ];
};

const Sidebar = () => {
  const { pathname } = useRouter();
  const activeOperation = useActiveOperation();
  const { operationType } = useUserDetails();
  const router = useRouter();
  const { data: userInfo } = useGetCurrentUserInfo();

  const generatePermissionMap = () => {
    const currentModule = activeOperation;
    const mappedData = userInfo?.detailInfo.UserAssignedFeaturePermissions.filter(
      ({ FeaturePermission }) => FeaturePermission.moduleName === currentModule
    ).reduce((acc: Record<string, boolean>, permissionInfo) => {
      if (
        (permissionInfo.readable || permissionInfo.writable) &&
        !acc[permissionInfo.FeaturePermission.feature]
      ) {
        acc[permissionInfo.FeaturePermission.feature] = true;
      }
      return acc;
    }, {});
    return mappedData;
  };

  const menuList = useMemo(() => {
    const updatedPermissionMap = generatePermissionMap() ?? {};
    const isSupportUser = userInfo?.roles.includes("Admin");

    const updatedMenuList =
      activeOperation === ModuleEnum.sales
        ? salesMenuItems(updatedPermissionMap, !!isSupportUser)
        : purchaseMenuItems(updatedPermissionMap, !!isSupportUser);
    return updatedMenuList.filter((item) => !item.hidden);
  }, [activeOperation, operationType, userInfo]);

  useEffect(() => {
    if (operationType && activeOperation !== operationType) {
      router.push(getOperationRoute("controlTower", operationType));
    }
  }, [activeOperation, operationType]);

  return (
    <div className="w-[60px] border-r border-strokes px-2.5 flex flex-col justify-center">
      {menuList.map((menu) => (
        <MenuItem menu={menu} key={menu.text} activeOperation={activeOperation} />
      ))}
    </div>
  );
};
export default Sidebar;
